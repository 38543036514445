<template>
	<b-modal ref="ModalEditPaillette" size="xl">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('action.confirm_expedie') }}</h2>
		</template>
		<template slot="default">
			<div v-if="show_resa">
				<div v-for="reservation in reservations_local" :key="reservation.id">
					<h5 class="mt-2">{{ reservation.computed_dest_exp }}</h5>
					<template v-if="reservation.paillettes_change > 0">
				        <label class="col-form-label">{{ $t('monte.nb_paillette_send') }}</label>
				        <input
				            type="number"
				            class="form-control"    
				            v-model="reservation.paillettes_change"
				            @change="changePaillette"           
				        >
				        <b-form-checkbox
				        	v-if="reservation.ask_create"
							class="custom-control custom-checkbox"
							v-model="reservation.add_resa"
						>
						{{ $t('monte.create_line_resa') }} {{ reservation.paillettes-reservation.paillettes_change }} {{ $t('monte.paillettes') }}
						</b-form-checkbox>
					</template>
					<template v-else>
						<label class="col-form-label">{{ $t('monte.nb_doses_send') }}</label>
				        <input
				            type="number"
				            class="form-control"    
				            v-model="reservation.doses_change"
				            @change="changeDose"           
				        >
				        <b-form-checkbox
				        	v-if="reservation.ask_create_doses"
							class="custom-control custom-checkbox"
							v-model="reservation.add_resa"
						>
						{{ $t('monte.create_line_resa') }} {{ reservation.doses-reservation.doses_change }} {{ $t('monte.doses') }}
						</b-form-checkbox>
					</template>
				</div>
			</div>
	        <b-form-checkbox
	        	v-if="ask_stock"
				class="custom-control custom-checkbox mt-4"
				switch
				v-model="sortie_paillettes"
			>
				{{ $t('monte.sortir_paillettes_stock') }}
			</b-form-checkbox>
			<div v-if="sortie_paillettes">
				<LoadingSpinner v-if="loading_stock"/>
				<table v-else class="table table-variante mt-4">
					<tr v-if="stocks.length > 0">
						<th>{{ $t('monte.stock_semence.horse') }}</th>
						<th>{{ $t('monte.stock_semence.dispo_tab') }}</th>
						<th>{{ $t('monte.stock_semence.numero_lot') }}</th>
						<th>{{ $t('monte.stock_semence.couleur') }}</th>
						<th>{{ $t('monte.stock_semence.localisation') }}</th>
						<th>{{ $t('monte.stock_semence.utilisation') }}</th>
						<th>{{ $t('monte.stock_semence.vers_tiers') }}</th>
					</tr>
					<tr v-for="stock in stocks" :key="stock.uid">
						<td>{{ stock.horse }}</td>
						<td>{{ stock.dispo }}</td>
						<td>{{ stock.lot }}</td>
						<td>{{ $t('monte.couleur.' + stock.couleur.semencecouleur_code) }}</td>
						<td>{{ stock.localisation }}</td>
						<td>
							<div class="form-group">
								<b-form-input
									v-model="stock.used"
									type="number"
									value="0"
									:max="stock.dispo"
								>
								</b-form-input>
							</div>
						</td>
						<td>
							<e-select
								v-model="stock.tiers"
							    id="tiers"
							    track-by="tiers_rs"
							    label="tiers_rs"
							    :placeholder="$t('tiers.search_tiers')"
							    :selectedLabel="$t('global.selected_label')"
							    :options="all_tiers"
							    :searchable="true"
							    :show-labels="false"
								:loading="loadingTiers"
							/>
						</td>
					</tr>
				</table>
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="submitModal()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.sauvegarder') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import MonteReservationOrder from "@/mixins/MonteReservationOrder.js"
	import StockSemence from "@/mixins/StockSemence.js"
	import Common from '@/assets/js/common'
	import Tiers from '@/mixins/Tiers'
	import _groupBy from 'lodash/groupBy'

	export default {
		name: 'ModalEditPaillette',
		mixins: [MonteReservationOrder, StockSemence, Tiers],
		props: {
			reservations: { type: Array, default: () => [] }
		},
		data () {
			return {
				processing: false,
				reservations_local: [],
				show_resa: true,
				sortie_paillettes: false,
				stocks: [],
				loading_stock: false,
				ask_stock: false,
				loadingTiers: false,
				all_tiers: []
			}
		},
		methods: {
			openModal(interne = false) {
				this.ask_stock = interne
				this.$refs.ModalEditPaillette.show()
			},
			closeModal() {
				this.$refs.ModalEditPaillette.hide()
			},
			async submitModal() {
				this.processing = true
				const expedition_date = new Date()
				let tab_promises = []

				this.reservations_local.forEach(resa => {
					let temp = {
						destinataire: resa.destinataire.tiers_id,
						stallion: 	resa.stallion.horse_id,
						doses: resa.doses,
						comment: resa.comment,
						client: resa.client ? resa.client.tiers_id : null,
						mare: resa.mare ? resa.mare.horse_id : null,
						contract: resa.contract ? resa.contract.contract_id : null,
						expediteur: resa.expediteur ? resa.expediteur.tiers_id : null,
						delivery_date: resa.delivery_date,
						canceled: false
					}

					if(resa.add_resa && resa.contract) {
						if(resa.paillettes > 0) {
							tab_promises.push(this.addContractSailliesReservations(resa.contract.contract_id, {...temp, paillettes: resa.paillettes - resa.paillettes_change}))
						}
						if(resa.doses > 0) {
							tab_promises.push(this.addContractSailliesReservations(resa.contract.contract_id, {...temp, doses: resa.doses - resa.doses_change}))
						}
					}

					if(resa.contract) {
						this.forceIndexationContractResa([resa.contract.contract_id])
					}

					temp.paillettes = resa.paillettes_change
					temp.doses = resa.doses_change
					if(temp.client) {
						tab_promises.push(this.editContractSailliesReservations(resa.id, temp))
					}
					tab_promises.push(this.setReservationOrderStatus(resa.id, { 
						supported_date: expedition_date,
						expedition_date
					}))

					// if(temp.paillettes > 0) {
					// 	tab_promises.push(this.addStockSemence([{
					// 		stallion: temp.stallion,
					// 		to: temp.destinataire,
					// 		from: temp.expediteur,
					// 		stock: temp.paillettes,
					// 		date: temp.delivery_date
					// 	}]))
					// }
				})

				if(this.ask_stock) {
					let stock_to_send = []
					this.stocks.forEach(stock => {
						if(Object.prototype.hasOwnProperty.call(stock, 'used') && stock.used > 0) {
							stock_to_send.push({
								stock: stock.used,
								date: new Date(),
								to: stock.tiers.tiers_id,
								id: stock.uid
							})
						}
					})
					if(stock_to_send.length > 0) {
						tab_promises.push(this.sortieStockSemence(stock_to_send))
					}
				}

				await Promise.all(tab_promises)

				this.processing = false
				this.$emit('ok')
				this.closeModal()
			},
			changePaillette() {
				this.show_resa = false
				this.reservations_local.forEach(resa => {
					resa.paillettes_change = parseInt(resa.paillettes_change)
					resa.ask_create = resa.paillettes > resa.paillettes_change
				})
				this.show_resa = true
			},
			changeDose() {
				this.show_resa = false
				this.reservations_local.forEach(resa => {
					resa.doses_change = parseInt(resa.doses_change)
					resa.ask_create_doses = resa.doses > resa.doses_change
				})
				this.show_resa = true
			},
			async loadPaillette() {
				this.loadingTiers = true
				this.all_tiers = await this.loadTiers()
				this.loadingTiers = false

				this.loading_stock = true
				let horses = []
				let horses_ids = []
				this.reservations_local.forEach(resa => {
					if(horses_ids.indexOf(resa.stallion.horse_id) == -1) {
						horses.push(resa.stallion)
						horses_ids.push(resa.stallion.horse_id)
					}
				})

				await Common.asyncForEach(horses, async (horse) => {
					let stock_horse = await this.loadAllStockLocalDetailsByStallion(horse.horse_id)
					const stocks_grouped = _groupBy(stock_horse, 'lot')
					for(let i in stocks_grouped) {
						this.stocks.push({
							...stocks_grouped[i][0],
							dispo: stocks_grouped[i].map(j => j.dispo).reduce((sum, a) => sum + a, 0),
							horse: horse.horse_nom,
							used: 0,
							tiers: this.all_tiers.find(tier => tier.tiers_id == this.reservations[0].destinataire.tiers_id)
						})
					}
				})
				this.loading_stock = false
			}
		},
		watch: {
			reservations: {
				handler(val){
					this.sortie_paillettes = false
					this.reservations_local = []
					val.forEach(resa => {
						resa.paillettes_change = JSON.parse(JSON.stringify(resa.paillettes))
						resa.doses_change = JSON.parse(JSON.stringify(resa.doses))
						resa.add_resa = false
						resa.ask_create = false
						resa.ask_create_doses = false
						this.reservations_local.push(resa)
					})
				},
				deep: true
			},
			sortie_paillettes(val) {
				this.stocks = []
				if(val) {
					this.loadPaillette()
				}
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>